





























































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

@Component({})
export default class AUser extends Vue {
  // 表格数据
  tableData = [
    {index: 1}
  ];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  config = {
    informationType: {
      1: '常见问题'
    },
    status: {
      0: "待发布",
      1: '已上线',
      2: '已下线'
    },
    influenceRange: {
      zhike: '掌纷享-直客',
      broker: '掌纷享-经纪人'
    }
  }
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
            .post("J27181", this.search)
            .then((data) => {
              this.total = data.total;
              this.tableData = data.list;
            })
            .finally(() => {
              this.loading = false;
            });
  }

  zixunDialog = false
  form: any = {
    informationType: '1'
  }
  to() {
    this.zixunDialog = false
    this.$router.push({
      path: '/tinymce/tinymce',
      query: {
        influenceRange: this.form.influenceRange
      }
    })
  }
  add() {
    this.zixunDialog = true
  }



  // 编辑
  edit(row:any) {
    this.$router.push({
      path: '/tinymce/tinymce',
      query: {
        informationCode: row.informationCode,
        influenceRange: row.influenceRange
      }
    })

  }

  // 操作
  setStatus(row: any, val: any){
    // 1 上架  2 下架 3 删除
    let tips: any = val == 1 ? "确定上线该资讯?" : (val == 2 ? '确定下线该资讯?' : ( val == 3 ? '确定删除该资讯?' : null ) )
    let apiVal: any = val == 1 ? "J27185" : (val == 2 ? 'J27186' : ( val == 3 ? 'J27187' : null ) )
    this.$confirm( tips , "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      api
              .post(apiVal, {informationCode: row.informationCode})
              .then((res: any) => {
                this.$message.success("操作成功");
                this.searchTable()
              })

    });
  }

  activated() {
    this.searchTable();
  }
}
